<template>
  <div class="login">
    <header class="header">
      <h1 class="title font-s-44 font-w-b">阳信水利综合管理平台</h1>
    </header>
    <main class="main flex flex-a-c flex-j-e" style="position: relative">
      <div class="block">
        <h1 class="title font-s-22 font-w-b">用户登录</h1>
        <form id="login" autocomplete="off">
          <label class="login-label">
            <i class="el-icon-mobile-phone"></i>
            <input
              id="user"
              type="text"
              maxlength="11"
              v-model="phone"
              autocomplete="true"
              placeholder="请输入11位手机号"
              :fetch-suggestions="querySearch"
            />
          </label>
          <div class="flex flex-j-s-b">
            <label class="login-label">
              <i class="el-icon-lock"></i>
              <input
                type="password"
                v-model="password"
                placeholder="请输入验证码"
                style="width: calc(110 / 1920 * 100vw)"
              />
            </label>
            <button
              class="btn-s-m btn-c-blue"
              type="button"
              :disabled="loading"
              @click.prevent="getCode"
            >
              {{ timer }}
            </button>
          </div>
          <button type="submit" class="btn-c-blue btn" @click.prevent="toHome">
            登录
          </button>
        </form>
        <div class="remember_pass">
          <div class="computLogin">
            <img src="../../assets/images/login/comput.png" alt="" />
          </div>
          <div class="testcom">阳信水利综合管理平台</div>
          <div>
            <el-checkbox v-model="checked">7天内免密登录</el-checkbox>
          </div>
        </div>
      </div>
      <div class="code">
        <div class="code_box">
          <span class="code_title">移动端下载</span>
          <div class="code_img">
            <!--                    <img class="img" src="@/assets/images/test_code.png" alt="123">-->
            <div class="qrcode" id="qrcode" ref="qrcode" />
          </div>
        </div>
      </div>
    </main>
    <!-- <div class="become_due_box">
			<div class="become_due">
				<div class="become_due_info">
					<span></span>
					<span>该平台服务已到期，请联系服务商。</span>
				</div>
				<div class="become_due_time">
					<p>东营旭瑞智能</p>
					<p>0546-8778677</p>
					<p>2023年07月14日</p>
				</div>
			</div>
		</div> -->
    <footer class="footer flex flex-j-c">
      <span class="font-s-20 font-w-500"
        >系统开发：山东安博智能科技有限公司</span
      >
      <span
        class="font-s-20 font-w-500"
        style="margin-left: calc(50 / 1920 * 100vw)"
        >技术支持：0543—3329409</span
      >
    </footer>
  </div>
</template>

<script>
import { login, code } from "../../api/login";
import { createSocket } from "../../assets/js/webscoket";
import { apk } from "@/utils/methods.js";
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      loading: false,
      phone: "",
      uuid: sessionStorage.getItem("uuid"),
      password: "",
      timer: "获取验证码",
      checked: false,
      restaurants: "",
    };
  },
  created() {
    if (localStorage.getItem("remember") == "true") {
      const time = 604800000;
      // console.log(localStorage.getItem('remember'))
      // console.log(localStorage.getItem('userInfo'))
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (new Date().getTime() - userInfo.date < time) {
        login({
          userName: userInfo.userName,
          password: userInfo.password,
        }).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("token", res.data.token);
            this.$router.push({ name: "main" });
          }
        });
      } else {
      }
    }
    // localStorage.clear();
    // localStorage.removeItem("token");
  },
  mounted() {
    document.getElementById("qrcode").innerHTML = "";
    new QRCode("qrcode", {
      width: 150,
      height: 150,
      text: apk(process.env.VUE_APP_CURENV),
    });
  },
  methods: {
    getCode() {
      if (this.phone == "") {
        this.$message.error("请输入手机号");
      } else {
        if (isNaN(this.phone)) {
          this.$message("请输入正确的手机号格式");
        } else {
          code({ userName: this.phone })
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(res.message);
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              if (err.message) {
                this.$message.error(err.message);
                return;
              }
              if (err.data.msg) {
                this.$message.error(err.data.msg);
              }
            });
        }
      }
    },
    // 登录
    toHome() {
      if (this.phone == "" || this.password == "") {
        this.$message.error("请输入手机号或验证码");
      } else {
        if (isNaN(this.phone)) {
          login({
            userName: this.phone,
            password: this.password,
          })
            .then((res) => {
              const arr = [];
              const userInfo = {
                userName: res.data.userName,
                userId: res.data.id,
              };
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("password", this.password);
              localStorage.setItem("id", res.data.id);
              localStorage.setItem("roleId", res.data.roleId);
              localStorage.setItem("remember", this.checked);
              localStorage.setItem("userName", res.data.userName);
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
              // console.log(res.data,'res.data');
              if (localStorage.getItem("userList")) {
                this.restaurants = JSON.parse(localStorage.getItem("userList"));
              } else {
                arr.push(res.data.userName);
                localStorage.setItem("userList", JSON.stringify(arr));
              }
              this.$router.push({ name: "index" });
              this.$message.success(res.message);
            })
            .catch((err) => {
              if (err.message) {
                this.$message.error(err.message);
                return;
              }
              if (err.data.msg) {
                this.$message.error(err.data.msg);
              }
            });
        } else {
          login({
            userName: this.phone,
            password: this.password,
          })
            .then((res) => {
              const arr = [];
              const userInfo = {
                userName: res.data.userName,
                userId: res.data.id,
              };
              if (localStorage.getItem("userList")) {
                this.restaurants = JSON.parse(localStorage.getItem("userList"));
              } else {
                arr.push(res.data.userName);
                localStorage.setItem("userList", JSON.stringify(arr));
              }
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("id", res.data.id);
              localStorage.setItem("roleId", res.data.roleId);
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
              localStorage.setItem("remember", this.checked);
              localStorage.setItem("userName", res.data.userName);
              this.$router.push({ name: "index" });
              this.$message.success(res.message);
            })
            .catch((err) => {
              if (err.message) {
                this.$message.error(err.message);
                return;
              }
              if (err.data.msg) {
                this.$message.error(err.data.msg);
              }
            });
        }
        // code({ userName: this.phone }).then(re@s => {
        //   console.log(res);
        //   if (res.code == 200) {
        //     localStorage.setItem("token", res.data.token);
        //     localStorage.setItem("id", res.data.id);
        //     // this.$router.push({ name: "main" });
        //     this.$message.success(res.message);
        //   } else {
        //     this.$message.error(res.message);
        //   }
        // });
      }
    },
    querySearch(queryString, cb) {
      if (JSON.parse(localStorage.getItem("userList").length)) {
        this.restaurants = JSON.parse(localStorage.getItem("userList"));
      } else {
        localStorage.setItem("userList", JSON.stringify());
      }
      const restaurants = this.restaurants;
      // console.log(restaurants)
      cb(restaurants);
    },
  },
};
</script>

//
<style lang="scss" scoped>
// 头部区域
.header {
  padding: calc(110 / 1080 * 100vh) 0 calc(41 / 1080 * 100vh) 0;
  background-color: #fff;
}
// 标题区域
.title {
  color: #2c599b;
  text-align: center;
}
// 主体体区域
.main {
  height: calc(690 / 1080 * 100vh);
  background-image: url("../../assets/images/login/login.png");
  background-size: 100%;
  .block {
    width: calc(285 / 1920 * 100vw);
    height: calc(382 / 1080 * 100vh);
    margin-right: calc(184 / 1920 * 100vw);
    padding: 0 calc(55 / 1920 * 100vw);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 0 21px rgba(255, 255, 255, 0.35);
    .title {
      color: #333333;
      text-align: left;
      margin: calc(30 / 1080 * 100vh) 0;
      padding: calc(10 / 1080 * 100vh) 0;
      border-bottom: 1px solid #d2d2d2;
      position: relative;
      font-size: calc(26 / 1920 * 100vw);
    }
    .title::after {
      content: "";
      width: calc(114 / 1920 * 100vw);
      height: calc(4 / 1080 * 100vh);
      background-color: #249fea;
      position: absolute;
      left: 0;
      top: calc(58 / 1080 * 100vh);
    }
    // 用户登录
    .login {
      color: #333333;
      margin: calc(46 / 1080 * 100vh) 0;
    }
    form {
      > div:last-child {
        margin-top: calc(10 / 1080 * 100vh);
      }
      label {
        border: 1px solid #e8ecf7;
        border-radius: 4px;
        > i {
          padding-right: calc(8 / 1920 * 100vw);
          color: #a6b6c6;
          cursor: text;
        }
      }
      .login-label {
        position: relative;
        > i {
          position: absolute;
          font-size: 14px;
          top: calc(50% - 7px);
          left: 7px;
        }
        > input {
          padding-left: 28px;
        }
      }
      input::placeholder {
        color: #a6b6c6;
        font-weight: 500;
        font-size: calc(12 / 1920 * 100vw);
      }
      > button {
        width: calc(271 / 1920 * 100vw);
      }
      input {
        width: 100%;
        height: calc(36 / 1080 * 100vh);
        &[type="checkbox"] {
          width: auto;
        }
      }
      button {
        height: calc(38 / 1080 * 100vh);
        background: #3a87db;
        border-radius: 4px;
        &:disabled {
          background-color: gray;
        }
      }
      .btn {
        width: 100%;
      }
    }
    #login {
      margin-bottom: calc(30 / 1080 * 100vh);
    }
    .remember_pass {
      position: relative;
      display: flex;
      align-items: center;
      color: #9b9b9b;
      ::v-deep .el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }
  .code {
    position: absolute;
    top: calc(150 / 1080 * 100vh);
    width: 20px;
    padding: 10px 5px 10px 12px;
    line-height: 18px;
    text-align: center;
    background: #ffffff;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    &:hover {
      cursor: pointer;
      & > .code_box > .code_img {
        opacity: 1;
        right: 36px;
        transition: right 0.5s;
      }
    }
    .code_box {
      position: relative;
      .code_title {
        color: #0084ff;
      }
      .code_img {
        position: absolute;
        top: -9px;
        right: 0px;
        opacity: 0;
        padding: 5px;
        background: #ffffff;
        padding: 5px;
        .qrcode {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}
// 底部区域
.footer {
  margin-top: calc(20 / 1080 * 100vh);
  color: #0d3158;
}
form {
  > div:last-child {
    margin-top: calc(50 / 1080 * 100vh);
  }
}
.become_due_box {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 99999;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
  top: 0;
  left: 0;
}

.become_due {
	width: 500px;
	height: 400px;
	background-image: url("http://files.xuruidea.com/206/20230414/jxsl_popup_bg1681452511181.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: relative;
}
.become_due_info{
	width: 80%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 17px;
	span{
		margin-top: 10px;
    color: #000;
    font-size: 18px;
	}
}
.become_due_time{
	position: absolute;
	right: 20px;
	bottom: 20px;
	font-size: 14px;
  color: #000;
	text-align: right;
}
label,
.label {
  display: flex;
  align-items: center;
  margin-bottom: calc(18 / 1080 * 100vh);
  > .label-text {
    width: calc(120 / 1920 * 100vw);
    display: inline-block;
    font-size: calc(16 / 1920 * 100vw);
    font-weight: 500;
    color: #778ca2;
  }
}
::v-deep .el-checkbox {
  position: absolute;
  top: 1px;
  margin-left: 12px;
  color: #778ca2;
}
.computLogin {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.testcom {
  color: #3a87db;
}
</style>
